import * as React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import ReadyOrderListItem from "./readyOrderListItem"
import { setReadyTvOrdersFunc } from "../helperFunc/mainImportFilter"

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderListOnlineBox: {
    width: "100%",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    height: "100%",
    borderRadius: "1rem",
    backgroundColor: theme.palette.common.black,
    overflow: "hidden",
  },

  fullHGrid: {
    height: "100%",
  },

  orderListLocalBox: {
    width: "100%",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    height: "100%",
    borderRadius: "1rem",
    backgroundColor: theme.palette.common.black,
    overflow: "hidden",
  },

  inGridLeft: {
    paddingRight: "1rem",
    paddingLeft: "2rem",
    paddingTop: "2rem",

    height: "100%",
    overflow: "hidden",
  },

  inGridRight: {
    paddingRight: "2rem",
    paddingLeft: "1rem",
    paddingTop: "2rem",

    height: "100%",
    overflow: "hidden",
  },

  orderListGrid: { width: "100%" },

  outBox: {
    width: "100%",
    minHeight: "calc(70svh  - 350px)",
    position: "relative",
  },
  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  icon: {
    color: theme.palette.common.blackNine,
    fontSize: "3rem !important",
  },

  sectionTitle: {
    fontSize: "2.5rem",
    marginBottom: "0.5rem",
  },
}))

const ReadyOrderList = ({ ordersIn, localOrdersIn }) => {
  const [tvOnlineReady, setTvOnlineReady] = React.useState([])
  const [tvLocalReady, setTvLocalReady] = React.useState([])
  const classes = useStyles()
  React.useEffect(() => {
    let orders = setReadyTvOrdersFunc(ordersIn, localOrdersIn)
    setTvOnlineReady(orders.tvOnlineReady)
    setTvLocalReady(orders.tvLocalReady)
  }, [ordersIn, localOrdersIn])

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      classes={{ root: classes.fullHGrid }}
    >
      <Grid item xs={6} classes={{ root: classes.inGridLeft }}>
        <Box classes={{ root: classes.orderListOnlineBox }}>
          <Typography
            variant="body2"
            align="center"
            classes={{ root: classes.sectionTitle }}
          >
            {"ONLINE ORDERS"}
          </Typography>
          <Grid container>
            {tvOnlineReady.length > 0 ? (
              tvOnlineReady.map((orderItem, index) => (
                <ReadyOrderListItem
                  orderItem={orderItem}
                  totalCount={tvOnlineReady.length}
                  key={orderItem.id}
                  ind={index}
                />
              ))
            ) : (
              <Box classes={{ root: classes.outBox }}>
                <Box classes={{ root: classes.inBox }}>
                  <ErrorOutlineOutlinedIcon
                    classes={{ root: classes.icon }}
                  ></ErrorOutlineOutlinedIcon>
                  <Typography variant="body2">
                    {"No Online Orders Ready Yet"}
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={6} classes={{ root: classes.inGridRight }}>
        <Box classes={{ root: classes.orderListLocalBox }}>
          <Typography
            variant="body2"
            align="center"
            classes={{ root: classes.sectionTitle }}
          >
            {"IN-STORE ORDERS"}
          </Typography>
          <Grid container>
            {tvLocalReady.length > 0 ? (
              tvLocalReady.map((orderItem, index) => (
                <ReadyOrderListItem
                  orderItem={orderItem}
                  totalCount={tvLocalReady.length}
                  key={orderItem.orderNumber}
                  ind={index}
                />
              ))
            ) : (
              <Box classes={{ root: classes.outBox }}>
                <Box classes={{ root: classes.inBox }}>
                  <ErrorOutlineOutlinedIcon
                    classes={{ root: classes.icon }}
                  ></ErrorOutlineOutlinedIcon>
                  <Typography variant="body2">
                    {"No In-Store Orders Ready Yet"}
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ReadyOrderList
