import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import { makeStyles } from "@material-ui/core/styles"

import ReadyOrderList from "./readyOrderList"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
    height: "100%",
    paddingTop: "9rem",
    overflow: "hidden",
  },

  inMainGrid: {},

  gridBox: {
    backgroundColor: theme.palette.common.blackThree,

    height: "calc(70svh  - 150px)",
    overflow: "hidden",
  },

  secondBox: {
    backgroundColor: theme.palette.common.blackThree,

    height: "calc(32svh  - 150px)",
    overflow: "hidden",
  },

  alertTitle: {
    fontSize: "1.75rem !important",
    marginTop: "0.5rem",
    lineHeight: "2rem !important",
  },

  dialogText: {
    fontSize: "1.25rem !important",
    color: theme.palette.common.white,
    lineHeight: "1.5rem",
  },
  alertTitCont: {
    paddingBottom: 0,
  },
  butGridCont: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "1rem",
  },

  butGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  butDilog: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },

  alertPaper: {
    backgroundColor: theme.palette.common.redSelected,
  },
}))

const TvInCont = ({ ordersIn, localOrdersIn, kitchenSettings }) => {
  const classes = useStyles()

  let tvUrl = kitchenSettings.tvImage[0].url
  //console.log(ordersIn)

  return (
    <Grid
      container
      justifyContent="flex-start"
      classes={{ root: classes.mainContainer }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        classes={{ root: classes.inMainGrid }}
      >
        <Box classes={{ root: classes.gridBox }}>
          <ReadyOrderList ordersIn={ordersIn} localOrdersIn={localOrdersIn} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        classes={{ root: classes.inMainGrid }}
      >
        <Box
          classes={{
            root: classes.secondBox,
          }}
          alt="tvAdv"
          sx={{
            backgroundImage: `url(${tvUrl})`,
            backgroundPosition: `50% 50%`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
    </Grid>
  )
}

export default TvInCont
